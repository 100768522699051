@tailwind base;
@tailwind components;
@tailwind utilities;

body{
   font-family: 'Nexa', sans-serif;
   position:relative;
}
#inter{
  font-family: 'Inter', sans-serif;
}
#roboto{
  font-family: 'Roboto', sans-serif;
}
#background{
  background-image: url('./components/img/03-\ DETACHED\ View2.jpg');
  background-size:cover;
  background-position: center;
  background-repeat:no-repeat; 
  height:auto;
  width: 100%;
  /* display: block; */
}
#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); 
}

#backgroundTwo{
  background: url("components/img/image 1.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
#backgroundThree{
  background: url(components/img/Group3.png);
  background-size: cover; 
  background-repeat: no-repeat;
  width: 100%;
  height: 85vh;
}
#backgroundFour{
  background-image: url(components/img/Group5.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  margin-top: -20%;
}
.gr{
  border-radius: 12px;
  width:50%;
  background: linear-gradient(270deg, #C2DEFF -10.94%, rgba(0, 49, 122, 0.00) 83.46%);
  height:20vh;
}
@media only screen and (max-width: 600px) {
  #backgroundTwo{
    background: url("components/img/image 1.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 150vh;
  }
}
